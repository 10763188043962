<template>
  <div
    v-if="multiple"
    v-bind="$attrs"
    ref="baseInput"
    class="block select-2-base-input-block"
    :class="inputClass"
    :type="'text'"
    :placeholder="placeholder"
    :value="mainInputDisplayValue"
    style="appearance: none; cursor: pointer"
    @click.stop="openDropdown()">
    <span v-if="!anySelected" class="pseudo-placeholder">{{
      translateUcFirst(placeholder)
    }}</span>

    <block v-if="anySelected" class="flex flex-start gap-m flex-wrap">
      <spinner v-if="listLoading" text="core.select2.listLoading" />
      <badge
        v-else
        v-for="itemValue in value"
        theme="lead"
        class="badge--interactive"
        :auto-translate="translateList"
        icon-end="x"
        :size="size"
        @click.stop="deselectItem(getItemByValue(itemValue))">
        <span>{{
          translateList
            ? translate(getItemByValue(itemValue).label)
            : getItemByValue(itemValue).label
        }}</span>
      </badge>
    </block>
  </div>

  <input
    v-else
    v-bind="$attrs"
    ref="baseInput"
    v-model="value"
    autocomplete="false"
    class="select-2-base-input"
    :class="inputClass"
    :type="'text'"
    :placeholder="placeholder"
    :value="
    listLoading ? tu('core.select2.listLoading') : mainInputDisplayValue"
    style="appearance: none; cursor: pointer"
    @click.stop="openDropdown()" />

  <div
    ref="listDropDown"
    v-click-outside="{ handler: closeDropdownImmediate }"
    v-dropdown="{ opened: isDropDownOpened, applyAnimations: true }"
    class="select2-dropdown relative"
    @dropdown:closed="isDropDownOpened = false"
    @dropdown:opened="focusDropdown"
    @click.stop=""
    @keyDown="listKeyboardNavigation">
    <div v-if="showFilterField" class="filter-wrapper flex-between gap-m margin-m-bottom">
      <form-input
        ref="searchInput"
        v-model="textFilter"
        type="text"
        style=""
        class="select2-filter-input width-expand"
        autocomplete="false"
        :wrapper-margins="false"
        placeholder="core.select2.filterPlaceholder"
        icon="magnify"
        @keydown="listKeyboardNavigation"
        @focus="currentHighlight = null"
        @click="currentHighlight = null"></form-input>

      <icon
        v-show="showCloseIcon"
        class="select2-dropdown-close"
        :size="1.5"
        theme="gray"
        icon="x"
        @click="closeDropdownImmediate"></icon>
    </div>

    <icon
      v-if="!showFilterField"
      class="select2-dropdown-close select2-dropdown-close--absolute"
      :size="1.5"
      theme="gray"
      icon="x"
      @click="closeDropdownImmediate"></icon>

    <div class="options-wrapper">
      <span v-if="listFetchError">{{ translate("core.select2.fetchError") }}</span>

      <spinner v-if="listLoading" text="core.loading" />

      <clean-wrapper v-if="displayList.length > 0 && !listLoading && !listFetchError">
        <block
          v-for="(item, index) of displayList"
          :key="item.value"
          :ref="'option-' + index"
          :item="item"
          :index="index"
          :auto-translate="translateList"
          :tabindex="index + 1"
          class="dropdown-option-wrapper animate__fadeIn animate--fast"
          @click="toggleItemSelection(item), clearHighlight()">
          <component
            :is="itemComponent"
            v-temp-class-on="'click,indicating-selection,850'"
            :item="item"
            v-bind="item"
            :selected="isSelected(item)"
            :auto-translate="translateList"
            :class="{ highlight: index == currentHighlight }">
          </component>
        </block>
      </clean-wrapper>

      <clean-wrapper v-if="displayList.length < 1 && !listLoading && !listFetchError">
        <span class="animate__fadeIn animate--fast">{{
          translate("core.select2.noResults")
        }}</span>
      </clean-wrapper>
      <div v-show="isDisplayListTruncated" class="more-indicator">
        <span>{{
          translate("core.select2.resultShowingSummary", {
            visibleCount: maxListDisplayLength,
            allCount: fullDisplayList.length,
          })
        }}</span
        >&nbsp<span>{{
          translate("core.select2.resultsLimitedHint", {
            visibleCount: maxListDisplayLength,
            allCount: fullDisplayList.length,
          })
        }}</span>
      </div>
    </div>
  </div>

  <icon
    v-if="showChevron && !isDropDownOpened"
    class="we-icon icon field__icon field__icon--end animate__fadeIn animate--fastest"
    theme="gray"
    icon="chevron-down"
    style="pointer-events: auto; cursor: pointer"
    @click.stop="openDropdown()"></icon>
  <icon
    v-if="showChevron && isDropDownOpened"
    class="we-icon icon field__icon field__icon--end animate__fadeIn animate--fastest"
    theme="gray"
    icon="chevron-inline-end"
    style="pointer-events: auto; cursor: pointer"
    @click.stop="closeDropdown()"></icon>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import select2Mixin from "@/client/extensions/mixins/baseComponents/form/controls/input/select2Input.js";

export default {
  mixins: [select2Mixin],
  props: {},
  emits: [],
  setup: function (props) {
    let { asyncOps, asyncOpsReady } = asyncOperations(props);

    return { asyncOps, asyncOpsReady };
  },
  data: function () {
    return {
      // isDropDownOpened : true,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
// todo: the not-allowed cursor doesnt work
.field .field__element.select-2-base-input {
  max-width: 100%;
  min-width: 0;
}

.select2-filter-input {
  max-width: calc(100% - var(--base-margin) * 35)!important;
  min-width:0!important;

  .field__element {
    max-width: 100%;
    min-width:0!important;
  }
}
.select-2-base-input.disabled,
.select-2-base-input-block[disabled],
.select-2-base-input-block.disabled .select-2-base-input[disabled],
.select-2-base-input-block[disabled] {
  cursor: not-allowed !important;
  pointer-events: none !important;
  * {
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
}
</style>
<style scoped lang="scss">
// scrollbar styles for drop down

.select2-dropdown {
  width: 100%;
  background: var(--c-bg);
  border: 2px solid var(--c-gray);
  $scrollbar-bg: var(--c-gray-2);
  $scrollbar-color: var(--c-gray-5);

  max-height: 40vh;
  padding: 0;

  scrollbar-color: $scrollbar-color $scrollbar-bg;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-bg;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    outline: none;
  }

  .filter-wrapper {
    padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
  }

  .options-wrapper {
    overflow: auto;
    max-height: calc(40vh - 70px);
    margin-top: var(--margin-m);
    padding: 0 var(--margin-m);
    scrollbar-color: $scrollbar-color $scrollbar-bg;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar-bg;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-color;
      outline: none;
    }
  }

  &.open {
    border: 2px solid var(--c-lead);
  }

  .more-indicator {
    color: var(--c-gray-4);
    border-top: 1px solid var(--c-gray-2);
    margin-top: var(--margin-m);
    padding: var(--margin-m) var(--margin-s) var(--margin-s) var(--margin-s);
  }
}

.dropdown-option-wrapper {
  position: relative;
  background: var(--c-gray);
}

.select2-dropdown > * {
  cursor: pointer;
}

.filter-wrapper {
  align-items: center;
}

.select2-filter-input {
  max-width: calc(100% - var(--base-margin) * 35)!important;
  min-width:0!important;
}

.select2-dropdown-close {
  transform: none;
  transition: transform 200ms ease-in-out;

  &--absolute {
    position: absolute;
    top: var(--margin-s);
    inset-inline-end: var(--margin-s);
    z-index: 5;
    padding: var(--margin-s);
  }

  &:hover {
    transform: rotate(180deg);
  }
}
</style>
